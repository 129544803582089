import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { loginRequest } from "../../../authConfig";
import { parseJwt } from "../utils/commonUtils";
import { useMsal } from "@azure/msal-react";

const useUserRoles = () => {
  const { instance, inProgress } = useMsal();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (instance.getActiveAccount() !== null) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount() ?? undefined,
        })
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          var payload = parseJwt(accessToken);
          setRoles(payload.roles);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({ ...loginRequest });
          } else {
            console.log(error);
            throw error;
          }
        });
    }
  }, [instance]);

  return roles;
};

export default useUserRoles;
