import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { theme } from "../../Common/UI/styles/theme";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import useUserRoles from "../../Common/UI/hook/useUserRoles";
import "./EmployeeSyncErrors.css";
import { appRoles, pageNames } from "../../authConfig";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import EmployeeSyncErrors from "./EmployeeSyncErrors";
import { PageContainer } from "../../Common/UI/components/PageContainer";

function EmployeeSyncToPosErrorsView(): ReactElement {
  const showSyncReport = useIsAuthorized([
    appRoles.Integration.ViewSyncErrorReport,
  ]);
  const execute = useFetchMsal();
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.integrations, pageNames.intEmployeeSync],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        {showSyncReport ? <EmployeeSyncErrors></EmployeeSyncErrors> : <></>}
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

export default EmployeeSyncToPosErrorsView;
