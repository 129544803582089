import { Button, Grid, Typography } from "@mui/material";
import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import useFetchMsal from "../hook/useFetchMsal";
import { theme } from "../styles/theme";
import "./IntegrationStatuses.css";
import AlbumIcon from "@mui/icons-material/Album";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { appRoles, config } from "../../../authConfig";
import { CardContainer } from "./CardContainer";
import { LoadingIndicator } from "./Loading";
import CreateTicket from "./CreateTicket";
import AddIcon from "@mui/icons-material/Add";

function IntegrationStatuses({
  showDateFilter = false,
}: {
  showDateFilter?: boolean;
}): ReactElement {
  const [punchesValidation, setPunchesValidation] = useState<boolean>(false);
  const [punchesAdjValidation, setPunchesAdjValidation] =
    useState<boolean>(false);
  const [rewardsValidation, setRewardsValidation] = useState<boolean>(false);
  const [dailyPayValidation, setDailyPayValidation] = useState<boolean>(false);
  const [crunchTimeValidation, setCrunchTimeValidation] =
    useState<boolean>(false);
  const [domoValidation, setDomoValidation] = useState<boolean>(false);
  const [domoCuratedValidation, setDomoCuratedValidation] =
    useState<boolean>(false);
  const [searchDate, setSearchDate] = useState<Dayjs | null>(null);
  const [dateError, setDateError] = useState<boolean>(true);

  const execute = useFetchMsal();
  const [loadingPunches, setLoadingPunches] = useState<boolean>(true);
  const [loadingPunchesAdj, setLoadingPunchesAdj] = useState<boolean>(true);
  const [loadingRewards, setLoadingRewards] = useState<boolean>(true);
  const [loadingDailyPay, setLoadingDailyPay] = useState<boolean>(true);
  const [loadingCrunchTime, setLoadingCrunchTime] = useState<boolean>(true);
  const [loadingDomo, setLoadingDomo] = useState<boolean>(true);
  const [loadingDOMOCurated, setLoadingDomoCurated] = useState<boolean>(true);

  const punchesExportPOStoUKG = "Employee Punches Export from POS to UKG";
  const adjPOStoUKG = "Employee Punch Adjustments Export from POS to UKG";
  const rewards = "Torch Heroes - Export File to Rewards Gateway";
  const dailyPay = "Bartender Tips Export from UKG to DailyPay";
  const dailyPayNote = "*** Status should turn green after 3 PM";
  const crunchTimeStatus = "CrunchTime – Import data into Datalake";
  const domoDataPull = "DOMO – Import data into Datalake";
  const domoDataCuratedProcessed = "DOMO – Filtered data into curated database";

  function getIntegrations(date = dayjs().format("YYYY-MM-DD")) {
    getStatusCall("punches", date, setPunchesValidation, setLoadingPunches);
    getStatusCall(
      "adjustments",
      date,
      setPunchesAdjValidation,
      setLoadingPunchesAdj
    );
    getStatusCall("rewards", date, setRewardsValidation, setLoadingRewards);
    getStatusCall("dailyPay", date, setDailyPayValidation, setLoadingDailyPay);
    getStatusCall(
      "ImportCtUnpostedDsr",
      date,
      setCrunchTimeValidation,
      setLoadingCrunchTime
    );
    getStatusCall("DOMO", date, setDomoValidation, setLoadingDomo);
    getStatusCall(
      "DOMOCurated",
      date,
      setDomoCuratedValidation,
      setLoadingDomoCurated
    );
  }

  const getStatusCall = function (
    urlParamName: string,
    date: string,
    setValidation: (value: React.SetStateAction<boolean>) => void,
    setLoading: (value: React.SetStateAction<boolean>) => void
  ) {
    setLoading(true);
    const urlCrunches = config.rootAPIList.integrationStatus
      .replace("{name}", urlParamName)
      .replace("{date}", date);
    execute("GET", `${config.rootAPIUrl}${urlCrunches}`)
      .then((result) => {
        setValidation(result.successful as boolean);
      })
      .catch((error) => {
        setValidation(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const search = function () {
    if (searchDate) {
      const date = searchDate.format("YYYY-MM-DD");
      console.log(date);
      getIntegrations(date);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  return (
    <CardContainer
      className="errored-integrations"
      title="Latest Run Integration Status"
    >
      {showDateFilter ? (
        <>
          <Grid container sx={{ width: "350px" }}>
            <Grid item xs={6}>
              <DatePicker
                onError={(newError) => {
                  if (newError !== null) {
                    setDateError(true);
                  }
                }}
                value={searchDate}
                onChange={(newValue) => {
                  setSearchDate(newValue);
                  setDateError(false);
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button onClick={search} disabled={dateError}>
                Search
              </Button>
            </Grid>
          </Grid>
          <div className="spacer-small"></div>
        </>
      ) : (
        <></>
      )}

      <Grid container className="grid-header">
        <Grid item xs={8}>
          <Typography>Integration Name</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Typography>Status</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Typography>Ticket</Typography>
        </Grid>
      </Grid>

      <IntegrationRow
        name={punchesExportPOStoUKG}
        loading={loadingPunches}
        result={punchesValidation}
      ></IntegrationRow>
      <IntegrationRow
        name={adjPOStoUKG}
        loading={loadingPunchesAdj}
        result={punchesAdjValidation}
      ></IntegrationRow>
      <IntegrationRow
        name={rewards}
        loading={loadingRewards}
        result={rewardsValidation}
      ></IntegrationRow>
      <IntegrationRow
        name={dailyPay}
        loading={loadingDailyPay}
        result={dailyPayValidation}
        note={dailyPayNote}
      ></IntegrationRow>
      <IntegrationRow
        name={crunchTimeStatus}
        loading={loadingCrunchTime}
        result={crunchTimeValidation}
      ></IntegrationRow>
      <IntegrationRow
        name={domoDataPull}
        loading={loadingDomo}
        result={domoValidation}
      ></IntegrationRow>
      <IntegrationRow
        name={domoDataCuratedProcessed}
        loading={loadingDOMOCurated}
        result={domoCuratedValidation}
      ></IntegrationRow>
    </CardContainer>
  );
}
export default IntegrationStatuses;

const IntegrationRow = function ({
  name,
  loading,
  result,
  note,
}: {
  name: string;
  loading: boolean;
  result: boolean;
  note?: string;
}): ReactElement {
  return (
    <Grid container className="grid-container">
      <Grid item xs={8}>
        <Typography>{name}</Typography>
        <Typography sx={{ fontSize: "12px" }}>{note}</Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <LoadingIndicator show={loading}>
          {!result ? (
            <AlbumIcon htmlColor={theme.palette.error.main}></AlbumIcon>
          ) : (
            <AlbumIcon htmlColor={theme.palette.success.main}></AlbumIcon>
          )}
        </LoadingIndicator>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", justifyContent: "end", alignItems: "baseline" }}
      >
        <CreateTicket
          buttonTitle={
            <>
              <AddIcon sx={{ marginRight: "10px" }} /> Ticket
            </>
          }
          ticketDescription={`${name} ${dayjs().format(
            "MM-DD-YYYY"
          )} FAILED please review issue.`}
          ticketSubject={`${name} ${dayjs().format("MM-DD-YYYY")} FAILED`}
        ></CreateTicket>
      </Grid>
    </Grid>
  );
};
