import { Typography } from "@mui/material";
import { theme } from "../../../Common/UI/styles/theme";

export default function ReleaseOnePointSix() {
  return (
    <>
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        {" "}
        RELEASE 1.6 – October 7th, 2024
      </Typography>
      <ul>
        <li>
          Dashboard Home -
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> This release will introduce POS Terminal
              Status as per available in Bomgar. POS Terminal Offline Widget
              will provide total number of stores that have all their terminals
              down (excluding MobilePOS) while POS Terminals Status should
              provide list of store/terminals online status.{" "}
            </li>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> No more waiting for the page to load all
              widget. This release introduces improved performance. Data will be
              refreshed behind the scenes and should include date & time of last
              refresh. For more up-to-date status, no sweat, just hit Refresh
              button. Voila!{" "}
            </li>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> - Latest Run Integration Status – Added DOMO
              Sales data into Data Lake Pipeline statues{" "}
            </li>
          </ul>
        </li>

        <li>
          Store 411 –
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> Introducing new landing page! Forget about
              the Store Directory Smartsheet, you can do it all here: Filter,
              add columns, and Export all in this new Grid format!
            </li>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> POS Terminals tab – Now you can find all POS
              Terminals active under each store and last access timestamp.{" "}
            </li>
          </ul>
        </li>

        <li>
          Bug Fixes:
          <ul>
            <li style={{ listStyleType: "circle" }}>
              Update CardFree Online Status to include if HOO were deleted.{" "}
            </li>
            <li style={{ listStyleType: "circle" }}>
              Fix the issue of updating WAN IP Address if no data exists.
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}
