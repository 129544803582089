import { useState, useEffect, ReactNode } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest, config } from "../../../authConfig";
import useFetchMsal from "../hook/useFetchMsal";
import { parseJwt } from "./commonUtils";

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard = ({
  ...props
}: {
  roles: string[];
  children: ReactNode;
}) => {
  const { instance, inProgress } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const activeAccount: null | AccountInfo = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount() ?? undefined,
        })
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          var payload = parseJwt(accessToken);

          if (!!payload.roles) {
            let intersection = props.roles.filter((role) =>
              payload.roles.includes(role)
            );
            if (intersection.length > 0) {
              setIsAuthorized(true);
            }
          }

          if (props.roles.length == 0) {
            setIsAuthorized(true);
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({ ...loginRequest });
          } else {
            console.log(error);
            throw error;
          }
        });
    }
  }, [activeAccount, instance]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        ...loginRequest,
      }}
    >
      {isAuthorized ? (
        <>{props.children}</>
      ) : (
        <div className="data-area-div">
          <h3>You are unauthorized to view this content.</h3>
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
};
