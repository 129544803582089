import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { CardContainer } from "../../Common/UI/components/CardContainer";

export default function ManagePosEmployeeHelp() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        The <a href="/employee-lookup">MANAGE POS EMP</a> feature provides the
        ability to lookup an employee basic information in both UKG & POS in a
        side by side view.
      </Typography>
      <CardContainer>
        <ul>
          <li>
            <Typography variant="h6">
              Provide Employee # and click LOOKUP{" "}
            </Typography>
          </li>
          <li>
            <Typography variant="h6">Key Information provided: </Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  HIRE DATE: Please note hire date should match post Sept 2023.
                  POS will always a one day prior hire date{" "}
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  STATUS: Reflects employment status Employed or Terminated.
                  Note: if status is Terminated no other information will be
                  displayed.{" "}
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  LOCATIONS - Location code & name{" "}
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  OB CODE{" "}
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  JOB NAME{" "}
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  EFFECTIVE DATE: Effective date will only be available for POS
                  information as in UKG Locations do not have any effective
                  date. When a location is added in UKG, it will use same date
                  effective date in the POS and therefore same effective date
                  for subsequent job codes.{" "}
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant="h6">SYNC NOW button:</Typography>
          </li>
          <Typography variant="body1" component="span">
            If Key information (Locations, Job codes) are not matching between
            UKG & POS, a manual Sync can be performed by pushing the SYNC NOW
            button. 
            <br/>  <br/>
            <p style={{paddingLeft:"30px"}}>“Sync in progress…” Message should be displayed while sync
            process running. Once completed, Confirmation OR error message is
            displayed. Error message should be display in red.</p>
          </Typography>

          <li>
            <Typography variant="h6">UPDATE EFFECTIVE DATE:</Typography>
            <Typography variant="body1" component="span">
              If a Job Code effective date requires to be backdated, and after
              obtaining Approval from Payroll team:
            </Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  Select JOB CODE from list of job codes
                </Typography>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  Enter new effective date provided from payroll team – Click
                  UPDATE
                </Typography>
              </li>
            </ul>
          </li>
        </ul>
      </CardContainer>
    </>
  );
}
