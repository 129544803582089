import { appRoles, pageNames } from "./authConfig";

import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import StoreInfoView from "./Store411/UI/StoreInfoView";
import JoinFullRoundedIcon from "@mui/icons-material/JoinFullRounded";
import IntegrationsView from "./Integrations/UI/IntegrationsView";
import HelpView from "./HelpPage/UI/HelpView";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ArticleIcon from "@mui/icons-material/Article";
import EmployeeSyncToPosErrorsView from "./ErrorReporting/UI/EmployeeSyncToPosErrorsView";
import BadgeIcon from "@mui/icons-material/Badge";
import EmployeeLookupView from "./EmployeeSync/UI/EmployeeLookupView";
import { ReactElement } from "react";
import DailyReporting from "./Reporting/UI/DailyReporting";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Stores } from "./Store411/UI/Stores";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { ProductCatalog } from "./ProductCatalog/UI/ProductCatalog";
import CodeIcon from "@mui/icons-material/Code";
import { DevDashboard } from "./DevDashboard/UI/DevDashboard";

export interface NavbarRoutes {
  name: string;
  icon: ReactElement | null;
  routerPath: string | null;
  control: ReactElement | null;
  roles: string[];
  isNavItem: boolean;
  isActivePage: boolean;
  order: number;
  children: NavbarRoutes[] | null;
}

const MetaNavbarRoutes = {
  getRoutes: function (): NavbarRoutes[] {
    return [
      {
        name: pageNames.integrations,
        icon: <JoinFullRoundedIcon htmlColor="#FFFFFF" />,
        routerPath: null,
        control: null,
        roles: [],
        isNavItem: true,
        isActivePage: false,
        order: 200,
        children: [
          {
            name: pageNames.intStatus,
            icon: null,
            routerPath: "integration-statuses",
            control: <IntegrationsView></IntegrationsView>,
            roles: [appRoles.Integration.ViewStatus],
            isNavItem: true,
            isActivePage: true,
            order: 1,
            children: [],
          },
          {
            name: pageNames.intEmployeeSync,
            icon: <ArticleIcon htmlColor="#FFFFFF" />,
            routerPath: "employee-sync-errors",
            control: (
              <EmployeeSyncToPosErrorsView></EmployeeSyncToPosErrorsView>
            ),
            roles: [appRoles.Integration.ViewSyncErrorReport],
            isNavItem: true,
            isActivePage: true,
            order: 2,
            children: [],
          },
        ],
      },
      {
        name: pageNames.helpPage,
        icon: <HelpCenterIcon htmlColor="#FFFFFF" />,
        routerPath: "help",
        control: <HelpView></HelpView>,
        roles: [],
        isNavItem: false,
        isActivePage: true,
        order: 0,
        children: null,
      },
      {
        name: pageNames.managePOSEmployee,
        icon: <BadgeIcon htmlColor="#FFFFFF" />,
        routerPath: "employee-lookup",
        control: <EmployeeLookupView></EmployeeLookupView>,
        roles: [appRoles.EmployeeLookup],
        isNavItem: true,
        isActivePage: true,
        order: 100,
        children: [],
      },
      {
        name: pageNames.reports,
        icon: <SummarizeIcon htmlColor="#FFFFFF" />,
        routerPath: "reports",
        control: <DailyReporting></DailyReporting>,
        roles: [appRoles.Reporting],
        isNavItem: true,
        isActivePage: true,
        order: 300,
        children: [],
      },
      {
        name: pageNames.store411,
        icon: <HolidayVillageIcon htmlColor="#FFFFFF" />,
        routerPath: "store-411",
        control: <Stores></Stores>,
        roles: [appRoles.StoreInfo.ViewStores],
        isNavItem: true,
        isActivePage: true,
        order: 500,
        children: [
          {
            name: pageNames.store411Info,
            icon: null,
            routerPath: "store-411/:storeId",
            control: <StoreInfoView></StoreInfoView>,
            roles: [appRoles.StoreInfo.ViewStores],
            isNavItem: false,
            isActivePage: true,
            order: 1,
            children: [],
          },
        ],
      },
      {
        name: pageNames.productCatalog,
        icon: <MenuBookIcon htmlColor="#FFFFFF" />,
        routerPath: "products",
        control: <ProductCatalog></ProductCatalog>,
        roles: [appRoles.ProductCatalog],
        isNavItem: true,
        isActivePage: true,
        order: 600,
        children: [],
      },
      {
        name: pageNames.devDashboard,
        icon: <CodeIcon htmlColor="#FFFFFF" />,
        routerPath: "devDashboard",
        control: <DevDashboard></DevDashboard>,
        roles: [appRoles.DevDashboard],
        isNavItem: true,
        isActivePage: true,
        order: 700,
        children: [],
      },
    ];
  },
};

export default MetaNavbarRoutes;
