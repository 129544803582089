import "./EmployeeLookup.css";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Card,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { theme } from "../../Common/UI/styles/theme";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { appRoles, config } from "../../authConfig";
import {
  HRJobRate,
  POSJobRates,
  PayloadUpdateEffectiveDate,
  UserProfile,
} from "./EmployeeTypes";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import { Tooltip } from "@mui/material";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";

const syncRoles = [appRoles.EmployeeSyncErrors];
const effectiveDateRoles = [appRoles.EmployeeEffectiveDateUpdate];

function EmployeeDetails({
  userProfile,
  refresh,
}: {
  userProfile: UserProfile;
  refresh: () => void;
}): ReactElement {
  const showSync = useIsAuthorized(syncRoles);
  const showEffectiveDateUpdate = useIsAuthorized(effectiveDateRoles);
  const showRates = useIsAuthorized([appRoles.EmployeeSyncShowRates]);

  const [syncMsg, setSyncMessage] = useState("");
  const [syncMsgColor, setSyncMsgColor] = useState(theme.palette.primary.main);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [posJobRates, setPosJobRates] = useState<POSJobRates[]>([]);
  const [posJobLocation, setPosLocations] = useState<
    { name: string; number: string }[]
  >([]);
  const [selectedJobCode, setSelectedJobCode] = useState<string | undefined>(
    ""
  );
  const [updatedDate, setUpdatedDate] = useState<Dayjs | null>(null);
  const [updateMsg, setUpdateMsg] = useState<string | null>(null);
  const [updateMsgColor, setUpdateMsgColor] = useState(
    theme.palette.primary.main
  );
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
  const execute = useFetchMsal();

  const onEmployeeSync = function () {
    setSyncMessage("Sync in progress...");
    setSyncMsgColor("black");
    setSyncInProgress(true);

    const url = config.rootAPIList.employeeSync.replace(
      "{id}",
      userProfile?.employeeNumber.toString()
    );
    execute("POST", `${config.rootAPIUrl}${url}`)
      .then((msg) => {
        setSyncMessage("Sync finished!");
        setSyncMsgColor("black");
        setSyncInProgress(false);
        refresh();
      })
      .catch(async (error) => {
        if (error != "") {
          const msg: { message: string; status: boolean } = JSON.parse(error);
          console.log(msg);

          setSyncMessage(msg!.message);
          setSyncMsgColor(theme.palette.error.main);
        } else {
          setSyncMessage("Sync failed!");
          setSyncMsgColor(theme.palette.error.main);
        }
      })
      .finally(() => {
        setSyncInProgress(false);
      });
  };

  useEffect(() => {
    if (userProfile?.pos?.jobRates?.length > 0) {
      const firstLocation = userProfile?.pos?.jobRates[0]?.locationRef; // all location job rates must be the same
      const jRates = userProfile.pos.jobRates.filter(
        (x) => x.locationRef.number === firstLocation.number
      );
      setPosJobRates(
        jRates.sort((x, y) => {
          const a = parseInt(x.externalRef);
          const b = parseInt(y.externalRef);
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }
          return 0;
        })
      );

      //find

      let locations: { name: string; number: string }[] = [];

      for (let i = 0; i < userProfile.pos.jobRates.length; i++) {
        const jobRate = userProfile.pos.jobRates[i];
        const x = locations.filter(
          (x) => x.number === jobRate.locationRef.number
        );
        if (x.length === 0) {
          locations.push(jobRate.locationRef);
        }
      }

      setPosLocations(locations.sort(sortFunc));
    }
  }, [userProfile]);

  const sortFunc = (
    x: { number: string; name: string },
    y: { number: string; name: string }
  ) => {
    if (parseInt(x.number) > parseInt(y.number)) {
      return 1;
    } else if (parseInt(x.number) < parseInt(y.number)) {
      return -1;
    }
    return 0;
  };

  const handleChange = (event: SelectChangeEvent): void => {
    setSelectedJobCode(event.target.value as string);
    setUpdatedDate(dayjs(new Date()));
  };

  function updateEffectiveDate() {
    setUpdateMsgColor("black");

    if (!userProfile?.employeeNumber) {
      setUpdateMsg("Employee id is missing!");
      return;
    }

    if (!selectedJobCode) {
      setUpdateMsg("No job code has been selected!");
      return;
    }

    if (!updatedDate) {
      setUpdateMsg("No effective date has been selected!");
      return;
    }

    setUpdateMsg("Employee effective date update is in progress.");
    setLoadingIndicator(true);

    const payload: PayloadUpdateEffectiveDate = {
      employeeId: userProfile?.employeeNumber.toString(),
      externalRef: selectedJobCode,
      effectiveDate: updatedDate!.format("YYYY-MM-DD"), // local date. dayjs has the local date by default. To move to utc use the plugin
    };

    const url = config.rootAPIList.employeeUpdateDate;
    execute("PUT", `${config.rootAPIUrl}${url}`, payload)
      .then((data) => {
        setUpdateMsg("Effective date update finished!");
        refresh();
      })
      .catch((error) => {
        setUpdateMsgColor(theme.palette.error.main);
        if (error.status === 404) {
          setUpdateMsg("Employee not found!");
        } else {
          var result: { message: string } = JSON.parse(error);
          setUpdateMsg(result.message);
        }
      })
      .finally(() => {
        setLoadingIndicator(false);
      });
  }

  return (
    <>
      <CardContainer className="employee-lookup">
        <div className="employee-details-item-header">
          <div className="employee-name-container">
            <Typography variant="h6" className="employee-maintenance">
              Employee maintenance
            </Typography>
            <Grid container width={"400px"}>
              <Grid item xs={4}>
                <Typography className="employee-maintenance-name emploee-lookup-small-font">
                  Name
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="employee-maintenance-name emploee-lookup-small-font">
                  {userProfile?.employeeName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container width={"400px"}>
              <Grid item xs={4}>
                <Typography className="employee-maintenance-name emploee-lookup-small-font">
                  Employee No
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="employee-maintenance-name emploee-lookup-small-font">
                  {userProfile?.employeeNumber}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="spacer"></div>

        <GridTableHeader>
          <Grid item xs={2} className="details-section-header">
            &nbsp;
          </Grid>
          <Grid item xs={5} className="details-section-header">
            UKG
          </Grid>
          <Grid item xs={5} className="details-section-header">
            POS
          </Grid>
        </GridTableHeader>
        <GridTableRow>
          <Grid item xs={2} className="data-display">
            General info
          </Grid>
          <Grid item xs={5}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            &nbsp;
          </Grid>
        </GridTableRow>
        <GridTableRow>
          <Grid item xs={2} className="data-display">
            Hire date
          </Grid>
          <Grid item xs={5} className="font">
            {new Date(userProfile?.hr?.hireDate).toLocaleDateString()}
          </Grid>
          <Grid item xs={5} className="font">
            {new Date(userProfile?.pos.hireDate).toLocaleDateString()}
          </Grid>
        </GridTableRow>
        <GridTableRow>
          <Grid item xs={2} className="data-display">
            Status
          </Grid>
          <Grid item xs={5} className="font">
            {userProfile?.hr.employmentStatus === "True" ? (
              <span>Terminated</span>
            ) : (
              <span>Employed</span>
            )}
          </Grid>
          <Grid item xs={5} className="font">
            {userProfile?.pos.hireStatus === 1 ? (
              <span>Terminated</span>
            ) : (
              <span>Employed</span>
            )}
          </Grid>
        </GridTableRow>
        {(userProfile?.hr.employmentStatus !== "True" ||
          userProfile?.pos.hireStatus !== 1) && (
          <>
            <GridTableRow>
              <Grid item xs={2} className="data-display">
                Locations
              </Grid>
              <Grid item xs={5} className="">
                <ul className="ul-list location-list">
                  {userProfile?.hr?.locations
                    ?.sort(sortFunc)
                    .map((location) => {
                      return (
                        <li
                          key={`location - ${location.number}`}
                          className="data-display-list font"
                        >
                          {location.name}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
              <Grid item xs={5} className="">
                <ul className="ul-list location-list">
                  {posJobLocation.map((locationRef) => {
                    return (
                      <li
                        key={"pos_" + locationRef.number}
                        className="data-display-list font"
                      >
                        {locationRef.name}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </GridTableRow>
            <GridTableRow className="details-row">
              {/* <Grid item xs={2} className="data-display">Job details</Grid> */}

              <Grid item xs={12}>
                <div className="ukg-details">
                  <Grid container className="data-display">
                    <Grid item xs={1.5} className="fixed-label">
                      Job details
                    </Grid>
                    <Grid item xs={1.5} className="fixed-label">
                      Job code
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      className="fixed-label fixed-label-job-name"
                    >
                      Job name
                    </Grid>
                    {showRates ? (
                      <Grid item xs={1.5} className="fixed-label">
                        Job rate
                      </Grid>
                    ) : (
                      <Grid item xs={1.5}></Grid>
                    )}
                    <Grid item xs={1.5} className="fixed-label">
                      Job code
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      className=" fixed-label fixed-label-job-name"
                    >
                      Job name
                    </Grid>
                    {showRates ? (
                      <Grid item xs={1.5} className=" fixed-label">
                        Job rate
                      </Grid>
                    ) : (
                      <Grid item xs={1.5}></Grid>
                    )}
                    <Grid item xs={1.5}>
                      Effective date
                    </Grid>
                  </Grid>
                  <Grid container className="table-rows-container">
                    <Grid item xs={4.5} ml={"12.5%"}>
                      {userProfile?.hr?.jobRates
                        ?.sort((x, y) => {
                          const a = parseInt(x.jobCode);
                          const b = parseInt(y.jobCode);

                          if (a > b) {
                            return 1;
                          } else if (a < b) {
                            return -1;
                          }
                          return 0;
                        })
                        .map((jobRate) => {
                          return (
                            <Grid container className="data-display table-row">
                              <Grid item xs={4.25} className="fixed-label">
                                {jobRate.jobCode}
                              </Grid>
                              <Grid item xs={4} className="fixed-label">
                                <Tooltip title={jobRate.name}>
                                  <Typography noWrap={true}>
                                    {jobRate.name}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              {showRates ? (
                                <Grid item xs={2.25} className="fixed-label">
                                  {jobRate.jobRate}
                                </Grid>
                              ) : (
                                <Grid item xs={2.25}></Grid>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid item xs={6}>
                      {posJobRates.map((jobRate) => {
                        return (
                          <Grid
                            container
                            className="data-display table-row"
                            key={"pos_" + jobRate.externalRef}
                          >
                            <Grid
                              item
                              xs={2.75}
                              className="data-display-label fixed-label"
                            >
                              {jobRate.externalRef}
                            </Grid>
                            <Grid
                              item
                              xs={3.25}
                              className="data-display-value fixed-label"
                            >
                              <Tooltip title={jobRate.name}>
                                <Typography noWrap={true}>
                                  {jobRate.name}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            {showRates ? (
                              <Grid
                                item
                                xs={3}
                                className="data-display-value fixed-label"
                              >
                                {jobRate.regPayRate}
                              </Grid>
                            ) : (
                              <Grid item xs={3} />
                            )}

                            <Grid item xs={3} className="data-display-value">
                              {new Date(
                                jobRate.effectiveFrom
                              ).toLocaleDateString()}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* <Grid item xs={5} >
                                <div className="pos-details">
                                    <div className="data-display">
                                        <div className="fixed-label">
                                            Job code
                                        </div>
                                        <div className=" fixed-label fixed-label-job-name">
                                            Job name
                                        </div>
                                        {
                                            showRates ? <div className=" fixed-label">Job rate</div> : <></>
                                        }
                                        <div className="">
                                            Effective date
                                        </div>
                                    </div>
                                    {
                                        posJobRates.map((jobRate) => {
                                            return (<div className="data-display" key={'pos_'+jobRate.externalRef}>
                                                <div className="data-display-label fixed-label">
                                                    {jobRate.externalRef}
                                                </div>
                                                <div className="data-display-value fixed-label fixed-label-job-name">
                                                    <Tooltip title={jobRate.name}>
                                                        <Typography noWrap={true} >{jobRate.name}</Typography>
                                                    </Tooltip>
                                                </div>
                                                {
                                                    showRates ?
                                                        <div className="data-display-value fixed-label">
                                                            {jobRate.regPayRate}
                                                        </div>
                                                        : <></>
                                                }

                                                <div className="data-display-value fixed-label">
                                                    {new Date(jobRate.effectiveFrom).toLocaleDateString()}
                                                </div>
                                            </div>)
                                        })
                                    }
                                </div>

                            </Grid> */}
            </GridTableRow>
          </>
        )}
        {userProfile?.hr.employmentStatus !== "True" ||
        userProfile?.pos.hireStatus !== 1 ? (
          <Grid container>
            <Grid item xs={6}>
              <Box style={{ marginBottom: "10px", color: syncMsgColor }}>
                {syncMsg}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <div className="sync-container-button">
                {showSync && (
                  <Button
                    sx={{
                      width: "150px",
                      mt: "16px",
                    }}
                    disabled={syncInProgress}
                    onClick={(ev) => {
                      onEmployeeSync();
                    }}
                  >
                    Sync now
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </CardContainer>

      {/* Update date */}
      {userProfile?.hr.employmentStatus === "True" ? (
        <></>
      ) : (
        <>
          <div className="employee-details-container">
            {showEffectiveDateUpdate ? (
              <>
                <Typography variant="h6" className="employee-maintenance">
                  Update effective date
                </Typography>
                <Grid container className="employee-update-date">
                  <Grid item xs={3}>
                    <Typography className="emploee-lookup-small-font">
                      Employee No {userProfile?.employeeNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Job code
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedJobCode}
                        label="Job code"
                        onChange={handleChange}
                      >
                        {userProfile?.hr?.jobRates
                          ?.sort((x: HRJobRate, y: HRJobRate) => {
                            if (x.jobCode > y.jobCode) {
                              return 1;
                            } else if (x.jobCode < y.jobCode) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((x) => {
                            return (
                              <MenuItem
                                value={x.jobCode}
                                key={"hr2_" + x.jobCode}
                              >
                                {x.jobCode} - {x.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      value={updatedDate}
                      onChange={(newValue) => setUpdatedDate(newValue)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={updateEffectiveDate}>Update</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingIndicator show={loadingIndicator}>
                      <div
                        style={{ marginBottom: "10px", color: updateMsgColor }}
                      >
                        <Typography>{updateMsg}</Typography>
                      </div>
                    </LoadingIndicator>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="spacer"></div>
        </>
      )}
    </>
  );
}

export default EmployeeDetails;
