import { ReactElement, SetStateAction } from "react";
import { PageContainer } from "../../../Common/UI/components/PageContainer";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { theme } from "../../../Common/UI/styles/theme";
import LRSGrid, { LRSData } from "./LRSGrid";
import "./ReportingPage.css";

function ReportingPage(): ReactElement {
  const ReportTypes: { name: string; key: number }[] = [
    { name: "LRS", key: 1 },
    //{ name: "Sales Diff", key: 2 }, { name: "Punches Diff", key: 3 }
  ];

  const execute = useFetchMsal();
  const [searchDate, setSearchDate] = useState<Dayjs | null>(
    dayjs().add(-1, "day")
  );
  const [dateError, setDateError] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<number>(ReportTypes[0].key);
  const [lrsData, setLRSData] = useState<LRSData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>("");

  const search = function () {
    if (searchDate) {
      const date = searchDate.format("YYYY-MM-DD");
      setGeneralError("");

      switch (selectedType) {
        case 1:
          const url = config.rootAPIList.dailyReports
            .replace("{name}", "lrs")
            .replace("{date}", date);
          callApi(url, setLRSData, setLoading);
          break;

        default:
          break;
      }
    }
  };

  const callApi = (
    url: string,
    setData: (value: SetStateAction<any | undefined>) => void,
    setLoading: (value: SetStateAction<boolean>) => void
  ) => {
    setLoading(true);
    setData([]);
    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        setData(JSON.parse(result.data));
      })
      .catch((error) => {
        setGeneralError("Report for this date not available!");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      <CardContainer title="Reports" className="reports">
        <Grid container sx={{ maxWidth: "800px" }}>
          <Grid item xs={5} sx={{ pr: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="report-label">Report Type</InputLabel>
              <Select
                labelId="report-label"
                id="report-type"
                value={selectedType + ""}
                label="Age"
                onChange={(event: SelectChangeEvent) => {
                  setGeneralError("");
                  setSelectedType(event.target.value as unknown as number);
                }}
              >
                {ReportTypes.map((x) => (
                  <MenuItem key={x.key} value={x.key}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} sx={{ pr: "10px" }}>
            <DatePicker
              sx={{ width: "100%" }}
              onError={(newError) => {
                if (newError !== null) {
                  setDateError(true);
                }
              }}
              label={"Business Date"}
              value={searchDate}
              onChange={(newValue) => {
                setSearchDate(newValue);
                setDateError(false);
              }}
              disableFuture
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <Button onClick={search} disabled={dateError || loading}>
              Load
            </Button>
          </Grid>
        </Grid>
        <Box>
          <Typography color={theme.palette.error.main}>
            {generalError}
          </Typography>
        </Box>
        <div className="spacer-small"></div>

        <LoadingIndicator show={loading}>
          {selectedType !== 1 ? <></> : <LRSGrid lrsData={lrsData} />}
        </LoadingIndicator>
      </CardContainer>
    </PageContainer>
  );
}
export default ReportingPage;
