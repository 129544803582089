import { Home } from "./Home/UI/Home";
import { Logout } from "./Home/UI/Logout";
import ErrorBoundary from "./Common/UI/utils/ErrorBoundary";
import { RouteGuard } from "./Common/UI/utils/RouteGuard";
import { MenuItemInfo, MenuMetaInfo } from "./Common/UI/utils/commonTypes";
import { RouteObject } from "react-router-dom";
import { LogoutPage } from "./Home/UI-components/LogoutPage";
import MetaNavbarRoutes, { NavbarRoutes } from "./MetaNavbarRoutes";

interface MainCtrl {
  getAuthorizedMenuItems: (roles: string[]) => NavbarRoutes[];
  getRoutesConfig: () => RouteObject[];
}

type RouterInfo = {
  ComponentRef: any;
  router: string;
  navbarSource: MenuItemInfo;
  isNavItem: boolean;
  roles: string[];
};

// 1. authenticate
// 2. read user roles
// 3. load components
// 4. create routing
// 5. build sidebar/menu

export const MainController = function (): MainCtrl {
  // 3. load components
  const routes: NavbarRoutes[] = MetaNavbarRoutes.getRoutes();

  // 4. create routing
  const registerRoutes = function (route: NavbarRoutes) {
    if (route.isActivePage) {
      routesConfig.push({
        path: ("/" + route.routerPath) as string,
        element: (
          <RouteGuard roles={route.roles}>
            <ErrorBoundary>{route.control}</ErrorBoundary>
          </RouteGuard>
        ),
      });
    }

    route.children?.map((x) => registerRoutes(x));
  };

  var routesConfig: RouteObject[] = [];
  for (let index = 0; index < routes.length; index++) {
    registerRoutes(routes[index]);
  }

  routesConfig.push({
    path: "/",
    element: (
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    ),
  });

  routesConfig.push({
    path: "/logout",
    element: (
      <ErrorBoundary>
        <Logout />
      </ErrorBoundary>
    ),
  });
  routesConfig.push({
    path: "/logout-page",
    element: (
      <ErrorBoundary>
        <LogoutPage />
      </ErrorBoundary>
    ),
  });

  const getAuthorizedMenuItems = function (roles: string[]) {
    var authorizedRoutes = routes.filter(
      (item) =>
        item.isNavItem &&
        (item.roles.some((element) => roles?.includes(element)) ||
          item.roles.length === 0)
    );

    //just so first level children have authorization too.
    authorizedRoutes.map((x) => {
      const filtered =
        x.children?.filter(
          (child) =>
            child.isNavItem &&
            child.roles.some((element) => roles?.includes(element))
        ) ?? null;
      x.children = filtered;
    });

    //remove empty parents is they are not an active page themselfs
    authorizedRoutes = authorizedRoutes.filter((item) => {
      return (
        item.isActivePage === true ||
        (item.isActivePage === false &&
          item.children !== null &&
          item.children?.length > 0)
      );
    });

    return authorizedRoutes;
  };

  const getRoutesConfig = function () {
    return routesConfig;
  };

  return {
    getAuthorizedMenuItems,
    getRoutesConfig,
  };
};
