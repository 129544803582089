import { ReactElement, useRef } from "react";
import PipelinesGridProps from "../Interfaces/PipelinesGridProps";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import styles from "../Styles/DevDashboard.module.css";
import { Link } from "react-router-dom";

dayjs.extend(duration);

export const PipelinesGrid = ({
  isLoading,
  pipelineRuns,
}: PipelinesGridProps): ReactElement => {
  const gridRef = useRef<AgGridReact>(null);

  const dateComparator = (filterDate: Date, cellValue: any) => {
    const [date] = cellValue.split("T");

    if (date === null) {
      return 0;
    }

    const dateParts = date.split("-");
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2]);

    const cellDate = new Date(year, month, day);

    console.log(cellDate, filterDate);

    if (cellDate < filterDate) {
      return -1;
    } else if (cellDate > filterDate) {
      return 1;
    }
    return 0;
  };

  const col: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
      tooltipField: "name",
      minWidth: 250,
      cellRenderer: ({ data, value }: ICellRendererParams) => (
        <Link
          to={`https://web.azuresynapse.net/en/monitoring/pipelineruns/${data.id}?workspace=%2Fsubscriptions%2F1c9b989e-8521-46f9-b900-5fa836a5607d%2FresourceGroups%2Fdev-torchystacos-dw-eastus-rg%2Fproviders%2FMicrosoft.Synapse%2Fworkspaces%2Fsyntorchysdldev`}
          target="_blank"
        >
          {value}
        </Link>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      tooltipField: "status",
    },
    {
      field: "runStart",
      headerName: "Run Start",
      filter: "agDateColumnFilter",
      minWidth: 175,
      filterParams: {
        comparator: dateComparator,
      },
      valueGetter: ({ data }) =>
        dayjs(data.runStart).format("YYYY-MM-DDTHH:mm:ss"),
      tooltipValueGetter: ({ data }) =>
        dayjs(data.runStart).format("YYYY-MM-DDTHH:mm:ss"),
    },
    {
      field: "runEnd",
      headerName: "Run End",
      filter: "agDateColumnFilter",
      minWidth: 175,
      filterParams: {
        comparator: dateComparator,
      },
      valueGetter: ({ data }) =>
        dayjs(data.runEnd).format("YYYY-MM-DDTHH:mm:ss"),
      tooltipValueGetter: ({ data }) =>
        dayjs(data.runEnd).format("YYYY-MM-DDTHH:mm:ss"),
    },
    {
      field: "duration",
      headerName: "Duration",
      valueGetter: ({ data }) =>
        dayjs.duration(data.duration).format("HH:mm:ss"),
      tooltipValueGetter: ({ data }) =>
        dayjs.duration(data.duration).format("HH:mm:ss"),
    },
    {
      field: "message",
      headerName: "Message",
      tooltipField: "message",
    },
  ];

  return (
    <div className={`ag-theme-quartz ${styles.gridContainer}`}>
      <AgGridReact
        loading={isLoading}
        columnDefs={col}
        rowData={pipelineRuns}
        getRowId={({ data }) => data.id}
        pagination
        paginationPageSize={20}
        ref={gridRef}
        tooltipInteraction
        tooltipTrigger={"hover"}
        tooltipShowDelay={500}
        enableCellTextSelection
        onGridReady={() => gridRef.current?.api.sizeColumnsToFit()}
        onFirstDataRendered={() => gridRef.current?.api.sizeColumnsToFit()}
        defaultColDef={{
          filter: "agTextColumnFilter",
          filterParams: { buttons: ["apply", "clear"], closeOnApply: true },
          headerClass: "tableHeader",
        }}
      />
    </div>
  );
};
