import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#fc1000",
    },
    secondary: {
      main: "#FFBF00",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F9F9FB",
    },
    light: {
      default: "#FFFFFF",
    },
    selected: {
      default: "#ffffff",
    },
    success: {
      main: "#3EA619",
    },
    // text: {
    //   primary: '#fff', // Change '#00ff00' to the color you desire
    // },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Name of the rule

          "&.Mui-focused": {
            // increase the specificity for the pseudo class
            padding: "0 5px 0 5px",
            backgroundColor: "white",
          },
          "&.MuiFormLabel-filled": {
            padding: "0 5px 0 5px",
            backgroundColor: "white",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // Your custom styles for the dialog paper
          width: "50%",
          padding: "10px",
          borderRadius: "10px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            backgroundColor: theme.palette.secondary.main,
            color: "black",
          },
          borderRadius: "10px",
          marginLeft: "8px",
          marginRight: "8px",
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          minHeight: 0,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: "10px",
          border: "1px solid #D2D1D3",
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: "40px",
          borderRadius: "10px",

          boxShadow: "none",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          backgroundColor: theme.palette.secondary.main,

          color: "black",
          width: "120px",
          padding: "8px 20px",
          height: "40px",
          borderRadius: "10px",
          fontFamily: "Figtree",
          fontSize: "16px",
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&. MuiTypography-root": {
            fontSize: "14px",
          },
          fontSize: "14px",
        }),
      },
    },
  },
  typography: {
    fontFamily: [
      "Figtree",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
