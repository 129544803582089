import { Box, Button, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ReactElement, useState } from "react";
import { theme } from "../../../Common/UI/styles/theme";
import DateRangePickerProps from "../Interfaces/DateRangePickerProps";
import dayjs, { Dayjs } from "dayjs";

export const DateRangePicker = ({
  handleLoadClick,
  isLoading,
}: DateRangePickerProps): ReactElement => {
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "day").set("hour", 0).set("minute", 0).set("second", 0)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs().set("hour", 0).set("minute", 0).set("second", 0)
  );

  const [errorMessage, setErrorMessage] = useState<string>("");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: 1,
        }}
      >
        <Box>
          <DateTimePicker
            sx={{ mr: 3 }}
            disableFuture
            label={"Start Date"}
            value={startDate}
            onChange={(value) => {
              setStartDate(value);

              if (!!endDate && endDate.diff(value, "day") < 0) {
                setErrorMessage("Start date must be before the end date!");
              } else {
                setErrorMessage("");
              }
            }}
          />
          <DateTimePicker
            disableFuture
            label={"End Date"}
            value={endDate}
            onChange={(value) => {
              setEndDate(value);
              if (!!startDate && startDate.diff(value, "day") > 0) {
                setErrorMessage("Start date must be before the end date!");
              } else {
                setErrorMessage("");
              }
            }}
          />
        </Box>
        <Typography
          color={theme.palette.primary.main}
          sx={{
            userSelect: "none",
            msUserSelect: "none",
          }}
        >
          {errorMessage}
        </Typography>
      </Box>
      <Button
        disabled={!!errorMessage || isLoading}
        onClick={() => {
          handleLoadClick(
            startDate?.format("YYYY-MM-DDTHH:mm:ss") ?? "",
            endDate?.format("YYYY-MM-DDTHH:mm:ss") ?? ""
          );
        }}
      >
        Load
      </Button>
    </>
  );
};
