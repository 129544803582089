import React, { createContext, useEffect, useState } from "react";
import { ApplicationContextValue } from "./commonTypes";
// import { ApplicationOnOffState } from "../../models/ApplicationOnOffState";
// import { ApplicationStateAction } from "../../utils/applicationStateAction"
// import applicationStateReducer from "../../utils/applicationStateReducer";

interface Props {
  children: React.ReactNode;
}

const initialState: ApplicationContextValue = {
  state: {
    path: [],
  },
  setState: (state) => {},
};

export const ApplicationLocationContext =
  createContext<ApplicationContextValue>(initialState);

export const ApplicationState: React.FC<Props> = ({ children }) => {
  // const [state, dispatch] = useReducer(applicationStateReducer, initialState);
  const [appState, setAppState] = useState(initialState.state);

  return (
    <ApplicationLocationContext.Provider
      value={{ state: appState, setState: setAppState }}
    >
      {children}
    </ApplicationLocationContext.Provider>
  );
};
