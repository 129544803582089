export default function ProductCatalogHelp() {
  return (
    <>
      <p>
        The Product Catalog page provides a real time view of all menu items
        available in the POS along with key information below:
      </p>
      <ul>
        <li>PLU, Family Group, Major Group, Base Price, Medium Price, High Price
        <p>
          ** Note: Current view is from Test Environment until CNC API is
          available in Production{" "}
        </p></li>
      </ul>
    </>
  );
}
