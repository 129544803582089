import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Box,
  ButtonGroup,
  Card,
  Grid,
  TextField,
  Toolbar,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";
import {
  MouseEventHandler,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import SearchIcon from "@mui/icons-material/Search";
import { theme } from "../../Common/UI/styles/theme";
import { UserProfile } from "./EmployeeTypes";
import EmployeeDetails from "./EmployeeDetails";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import "./EmployeeLookup.css";
import { config, pageNames } from "../../authConfig";
import { LoadingIndicator } from "../../Common/UI/components/Loading";

function EmployeeLookupView(): ReactElement {
  const [employeeId, setEmployeeId] = useState("");
  const [lookupMsg, setLookupMessage] = useState("");
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const execute = useFetchMsal();
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.managePOSEmployee],
    });
  }, []);

  //@ts-ignore
  const onEmployeeLookup = function () {
    if (!employeeId) {
      setLookupMessage("Employee id is missing!");
      return;
    }

    setLookupMessage("");
    setLoadingIndicator(true);
    setUserProfile(null);

    const url = config.rootAPIList.employeeLookup.replace("{id}", employeeId);
    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((data) => {
        setLookupMessage("Employee data loading finished!");
        setUserProfile(data);
      })
      .catch((error) => {
        if (error.status === 404) {
          setLookupMessage("Employee not found!");
        } else {
          setLookupMessage("Employee data loading has failed!");
        }
      })
      .finally(() => {
        setLoadingIndicator(false);
      });

    // setUserProfile({
    //   "employeeNumber": 8223310,
    //   "employeeName": "Edwards, Selby",
    //   "hr": {
    //     "fullName": "Edwards, Selby A.",
    //     "locations": [
    //       {
    //           "number": "6",
    //           "name": "006 Forest Ln"
    //       },
    //       {
    //           "number": "81",
    //           "name": "081 Euless"
    //       }
    //   ],
    //     "jobRates": [
    //       {
    //         "jobCode": "1800",
    //         "name": "Rookie FOH",
    //         "jobRate": 13.000000
    //       },
    //       {
    //         "jobCode": "800",
    //         "name": "Cashier",
    //         "jobRate": 13.000000
    //       }
    //     ],
    //     "employmentStatus": "False",
    //     "hireDate": "2024-05-20 00:00:00",
    //     "payGroupCode": "STRTM",
    //     "salaryOrHourlyCode": "H",
    //     "email": "selbyaedwards@gmail.com",
    //     "orgLevelCode": "24"
    //   },
    //   "pos": {
    //     "hireDate": "2024-05-09",
    //     "role": "Rookie FOH",
    //     "hireStatus": 0,
    //     "jobRates": [
    //       {
    //         "excludeFromStaffRequirements": false,
    //         "primaryJob": true,
    //         "regPayRate": 11,
    //         "locationRef": {
    //           "number": "81",
    //           "name": "081 Euless"
    //         },
    //         "enterprisePOSRef": "24",
    //         "effectiveFrom": "2024-05-20T00:00:00-05:00",
    //         "externalPayrollId": "7051840",
    //         "externalRef": "1800",
    //         "name": "Rookie FOH"
    //       },
    //       {
    //             "excludeFromStaffRequirements": false,
    //             "primaryJob": true,
    //             "regPayRate": 11,
    //             "locationRef": {
    //               "number": "81",
    //               "name": "081 Euless"
    //             },
    //             "enterprisePOSRef": "24",
    //             "effectiveFrom": "2024-05-20T00:00:00-05:00",
    //             "externalPayrollId": "7051840",
    //             "externalRef": "720",
    //             "name": "Kitchen manager"
    //           },
    //       {
    //             "excludeFromStaffRequirements": false,
    //             "primaryJob": true,
    //             "regPayRate": 11,
    //             "locationRef": {
    //               "number": "102",
    //               "name": "102 Winter Garden"
    //             },
    //             "enterprisePOSRef": "24",
    //             "effectiveFrom": "2024-05-20T00:00:00-05:00",
    //             "externalPayrollId": "7051840",
    //             "externalRef": "720",
    //             "name": "Kitchen manager"
    //           }
    //     ]
    //   }
    // });
  };

  return (
    <AuthenticatedTemplate>
      <div className="lookup-container">
        <div>
          <Typography variant="h6" id="employee-lookup-title">
            Employee Lookup
          </Typography>
          <Grid container>
            <Grid
              item
              xs={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography>Employee </Typography>
              <Grid3x3Icon htmlColor={theme.palette.primary.main} />
            </Grid>

            <Grid item xs={6}>
              <div className="sync-container">
                <TextField
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={employeeId}
                  onChange={(ev) => {
                    setEmployeeId(ev.target.value);
                  }}
                />
                <Button
                  sx={{
                    margin: "20px",
                  }}
                  disabled={!employeeId}
                  onClick={(ev) => {
                    onEmployeeLookup();
                  }}
                >
                  Lookup
                </Button>
              </div>
            </Grid>
          </Grid>
          <div>{lookupMsg}</div>
        </div>
        <div className="spacer"></div>
        <LoadingIndicator show={loadingIndicator}>
          <div className="sync-container">
            {userProfile !== null ? (
              <div className="user-profile-card-container">
                <EmployeeDetails
                  userProfile={userProfile as UserProfile}
                  refresh={onEmployeeLookup}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </LoadingIndicator>
      </div>
    </AuthenticatedTemplate>
  );
}

export default EmployeeLookupView;
