import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "./Home/UI-components/NavBar";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { MainController } from "./MainController";
import { useEffect, useState } from "react";
import {
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { ReactElement } from "react";
import { loginRequest } from "./authConfig";
import { MenuItemInfo, MenuMetaInfo } from "./Common/UI/utils/commonTypes";
import { parseJwt } from "./Common/UI/utils/commonUtils";
import Box from "@mui/material/Box";
import { ApplicationState } from "./Common/UI/utils/ApplicationState";
import { BreadcrumbsBar } from "./Home/UI-components/BreadcrumbsBar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NavbarRoutes } from "./MetaNavbarRoutes";

const App = ({
  instance,
}: {
  instance: PublicClientApplication;
}): ReactElement => {
  const [authorizedMenuItems, setAuthorizedMenuItems] = useState<
    NavbarRoutes[]
  >([]);
  const mainCtrl = MainController();
  const router = createBrowserRouter(mainCtrl.getRoutesConfig());

  const load = () => {
    if (instance.getActiveAccount() !== null) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount() ?? undefined,
        })
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          var payload = parseJwt(accessToken);

          setAuthorizedMenuItems(
            mainCtrl.getAuthorizedMenuItems(payload.roles)
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({ ...loginRequest });
          } else {
            console.log(error);
            throw error;
          }
        });
    }
  };
  useEffect(() => {
    load();
  }, [instance]);

  instance.addEventCallback((event) => {
    //@ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
      load();
    }
  });

  return (
    <ApplicationState>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <Box sx={{ display: "flex" }}>
              <NavBar authorizedMenuItems={authorizedMenuItems} />
              <BreadcrumbsBar />
              <Grid
                justifyContent="center"
                sx={{
                  marginTop: "131px",
                  width: "100%",
                }}
              >
                <RouterProvider router={router} />
              </Grid>
            </Box>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </LocalizationProvider>
    </ApplicationState>
  );
};

export default App;
