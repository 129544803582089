import { Typography } from "@mui/material";
import { theme } from "../../../Common/UI/styles/theme";

export default function ReleaseOnePointSeven() {
  return (
    <>
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        {" "}
        RELEASE 1.7 - November 4th, 2024
      </Typography>
      <ul>
        <li>
          {" "}
          <strong>NEW</strong> Product Catalog -
          <ul>
            <li style={{ listStyleType: "circle" }}>
              The Product Catalog page provides a real time view of all menu
              items available in the POS along with key information below:
              <ul>
                <li>
                  PLU, Family Group, Major Group, Base Price, Medium Price, High
                  Price
                  <p>
                    ** Note: Current view is from Test Environment until CNC API
                    is available in Production
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          Store 411 -
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <strong>NEW</strong> Network Tab - Along with IP addresses for
              Connection 1 & 2, below fields are now added to each connection:
              <ul>
                <li>
                  Physical Interface, Type, Billing, ISP, Support Name, Support
                  Phone Number, Support Email, Static IP, Subnet, Gateway Notes
                </li>
                <li>
                  Only if you have the correct access, you would have ability to
                  edit this information
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}
