import "./FirstRow.css";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import { ReactElement, useEffect, useState } from "react";
import { NumberWidget } from "../../Common/UI/components/NumberWidget";
import { appRoles } from "../../authConfig";
import { FlexRow } from "../../Common/UI/components/FlexRow";
import { CardFreeInfo, WANStatuses, POSStatus, UberEats } from "./Interfaces";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import CellTowerIcon from "@mui/icons-material/CellTower";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

export const FirstRow = function ({
  loadingWANStatuses,
  WANStatuses,
  loadingCardFreeStatuses,
  cardFreeStatuses,
  loadingPOSStatuses,
  POSStatuses,
  loadingUberEatsStatuses,
  uberEatsStatuses,
}: {
  loadingWANStatuses: boolean;
  WANStatuses: WANStatuses[];
  loadingCardFreeStatuses: boolean;
  cardFreeStatuses: CardFreeInfo[];
  loadingPOSStatuses: boolean;
  POSStatuses: POSStatus[];
  loadingUberEatsStatuses: boolean;
  uberEatsStatuses: UberEats[];
}): ReactElement {
  const showFirewallStatus = useIsAuthorized([appRoles.Home.FirewallStatus]);
  const showCardFree = useIsAuthorized([appRoles.Home.CardFreeStatus]);
  const showPOSStatus = useIsAuthorized([appRoles.Home.ViewPOSStatus]);
  const showUberEats = useIsAuthorized([appRoles.Home.ViewUberEatsStatus]);
  const [posDate, setPOSDate] = useState<string | undefined>("");
  const [cfDate, setCFDate] = useState("");
  const [wanDate, setWANDate] = useState("");
  const [uberDate, setUberDate] = useState<string | undefined>("");

  const [storesDown, setStoresDown] = useState<number>(0);
  useEffect(() => {
    if (WANStatuses.length <= 0) return;

    const failedStores = WANStatuses.filter((x) => !x.status);
    setStoresDown(failedStores.length);
    setWANDate(WANStatuses[0].lastUpdated);
  }, [WANStatuses]);

  const [cardFreeDown, setCardFreeDown] = useState<number>(0);
  useEffect(() => {
    if (cardFreeStatuses.length <= 0) return;

    const failed = cardFreeStatuses.filter((x) => x.status === false);
    setCardFreeDown(failed.length);

    setCFDate(cardFreeStatuses[0].lastUpdated);
  }, [cardFreeStatuses]);

  //const [POSDown, setPOSDown] = useState<number>(0);
  //useEffect(() => {
  //  if(POSStatuses.length <= 0)
  //    return;

  //  const failed = POSStatuses.filter((x) => x.activeDevices <= 0);
  //  setPOSDown(failed.length);

  //  setPOSDate(POSStatuses[0].lastUpdated);
  //}, [POSStatuses]);

  const [uberEatsNumber, setUberEatsNumber] = useState<number>(0);
  useEffect(() => {
    if (uberEatsStatuses.length == 0) return;

    const failed = uberEatsStatuses.filter((x) => x.status === false);
    setUberEatsNumber(failed.length);

    setUberDate(uberEatsStatuses[0].lastUpdated);
  }, [uberEatsStatuses]);

  return (
    <FlexRow>
      {showFirewallStatus && (
        <NumberWidget
          number={storesDown}
          isLoading={loadingWANStatuses}
          text="Network Offline"
          icon={<CellTowerIcon />}
          className="down-number"
          lastUpdatedDate={wanDate}
        ></NumberWidget>
      )}
      {showCardFree && (
        <NumberWidget
          number={cardFreeDown}
          isLoading={loadingCardFreeStatuses}
          text="CardFree Stores Offline"
          icon={<OnlinePredictionIcon fontSize="large" />}
          className="down-number"
          lastUpdatedDate={cfDate}
        ></NumberWidget>
      )}
      
      {showUberEats && (
        <NumberWidget
          number={uberEatsNumber}
          isLoading={loadingUberEatsStatuses}
          text="Uber Eats Stores Offline"
          icon={<DirectionsCarIcon fontSize="large" />}
          className="down-number"
          lastUpdatedDate={uberDate}
        ></NumberWidget>)}
      {/* {showPOSStatus && (*/}
      {/*  <NumberWidget*/}
      {/*    number={POSDown}*/}
      {/*    isLoading={loadingPOSStatuses}*/}
      {/*    text="POS Terminals Offline"*/}
      {/*    icon={<MonitorIcon    fontSize="large" />}*/}
      {/*    className="down-number"*/}
      {/*    lastUpdatedDate={posDate}*/}
      {/*  ></NumberWidget>*/}
      {/*)}*/}

    </FlexRow>
  );
};
