import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { config, pageNames } from "../../authConfig";
import Product from "./Interfaces/Product";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Menu,
} from "@mui/material";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import RefreshIcon from "@mui/icons-material/Refresh";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import styles from "./Styles/ProductCatalog.module.css";
import DownloadIcon from "@mui/icons-material/Download";

export const ProductCatalog = (): ReactElement => {
  const { setState } = useContext(ApplicationLocationContext);
  const gridRef = useRef<AgGridReact>(null);

  const execute = useCallback(useFetchMsal(), []);

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
      field: "name",
      headerName: "Name",
      tooltipField: "name",
    },
    {
      field: "PLU",
      headerName: "PLU",
      tooltipField: "PLU",
    },
    {
      field: "familyGroup",
      headerName: "Family Group",
      tooltipField: "familyGroup",
    },
    {
      field: "majorGroup",
      headerName: "Major Group",
      tooltipField: "majorGroup",
    },
    {
      field: "price",
      headerName: "Base Price",
      filter: "agNumberColumnFilter",
      valueGetter: ({ data }) => Number(data.price),
      tooltipValueGetter: ({ value }) => (!value ? "None" : value),
      valueFormatter: ({ value }) => (!value ? "None" : value),
    },
    {
      field: "mediumPrice",
      headerName: "Medium Price",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: ({ value }) => (!value ? "None" : value),
      valueGetter: ({ data }) => Number(data.mediumPrice),
      valueFormatter: ({ value }) => (!value ? "None" : value),
    },
    {
      field: "highPrice",
      headerName: "High Price",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: ({ value }) => (!value ? "None" : value),
      valueGetter: ({ data }) => Number(data.highPrice),
      valueFormatter: ({ value }) => (!value ? "None" : value),
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    // },
    // {
    //   field: "allergen",
    //   headerName: "Allergen",
    // },
  ]);

  const fetchProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      const url = config.rootAPIList.productCatalog;
      const response: Product[] = await execute(
        "GET",
        `${config.rootAPIUrl}${url}`
      );

      setProducts(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [execute]);

  useEffect(() => {
    setState({
      path: [pageNames.productCatalog],
    });
  }, [setState]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleExportAsCsvButtonClick = () => {
    gridRef.current?.api.exportDataAsCsv({
      fileName: `export_${dayjs(dayjs()).format("YYYY_MM_DD_HH_mm_ss")}`,
    });
  };

  const handleColumnsMenuChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColDefs((prev) =>
      prev.map((col: ColDef) =>
        col.field === e.target.name ? { ...col, hide: !col.hide } : col
      )
    );
  };

  const handleRefreshButtonClick = async () => {
    try {
      setIsLoading(true);

      const url = config.rootAPIList.productCatalogRefresh;
      const response = await execute("GET", `${config.rootAPIUrl}${url}`);

      const affectedItemsCount = Number(response.message.split(" ").at(0));

      if (affectedItemsCount) {
        await fetchProducts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ px: 6 }}>
      <div className={`ag-theme-quartz ${styles.gridContainer}`}>
        <Box sx={{ mb: 1, display: "flex", columnGap: "8px" }}>
          <Button
            startIcon={<ViewWeekIcon />}
            onClick={(e) => setMenuAnchorElement(e.currentTarget)}
          >
            Columns
          </Button>
          <Button
            sx={{
              minWidth: "max-content",
            }}
            startIcon={<DownloadIcon />}
            onClick={handleExportAsCsvButtonClick}
          >
            Export as CSV
          </Button>
          <Menu
            open={Boolean(menuAnchorElement)}
            anchorEl={menuAnchorElement}
            onClose={() => setMenuAnchorElement(null)}
          >
            {colDefs.map((col: ColDef) => (
              <MenuItem key={col.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!col.hide}
                      name={col.field}
                      onChange={handleColumnsMenuChange}
                    />
                  }
                  label={col.headerName}
                />
              </MenuItem>
            ))}
          </Menu>
          <Button
            sx={{ ml: "auto" }}
            startIcon={<RefreshIcon />}
            onClick={handleRefreshButtonClick}
            disabled={isLoading}
          >
            Refresh
          </Button>
        </Box>
        <AgGridReact
          loading={isLoading}
          enableCellTextSelection
          getRowId={(params) => String(params?.data?.PLU)}
          rowData={products}
          columnDefs={colDefs}
          pagination
          paginationPageSize={50}
          tooltipInteraction
          tooltipShowDelay={500}
          defaultColDef={{
            filter: "agTextColumnFilter",
            filterParams: { buttons: ["apply", "clear"], closeOnApply: true },
            headerClass: "tableHeader",
          }}
          onGridReady={() => gridRef.current?.api.sizeColumnsToFit()}
          ref={gridRef}
        />
      </div>
    </Box>
  );
};
