import { Typography } from "@mui/material";

export default function ReportsHelp() {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        To Run key reports, select Report Name from REPORT TYPE Drop down,
        select BUSINESS DATE and click LOAD{" "}
      </Typography>
      <ul>
        <li>
          LRS REPORT: Shows list of stores and Order Counts reported. Stores
          that have 0 counts indicate an issue with LRS System at the store
        </li>
      </ul>
    </>
  );
}
