import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { theme } from "../../Common/UI/styles/theme";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { AccountInfo } from "@azure/msal-browser";
import { default as LogoutIcon } from "@mui/icons-material/Logout";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

const WelcomeName = () => {
  const { instance } = useMsal();
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const activeAccount: null | AccountInfo = instance.getActiveAccount();

  useEffect(() => {
    if ((activeAccount as AccountInfo) && !!activeAccount?.name) {
      setName(activeAccount?.name);
    } else {
      setName("");
    }
  }, [activeAccount]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClose = () => {
    setAnchorEl(null);
    instance.logoutRedirect();
  };

  if (name) {
    return (
      <>
        <Tooltip title="Idea Form">
          <IconButton
            href="https://forms.office.com/r/7GqTv8RGnm"
            target="_blank"
          >
            <EmojiObjectsIcon
              htmlColor={theme.palette.secondary.main}
              fontSize="large"
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Help">
          <IconButton href="/help">
            <HelpCenterIcon
              htmlColor={theme.palette.secondary.main}
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ borderRadius: "10px", p: "10px", pl: "15px" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* <AccountCircleIcon htmlColor={theme.palette.secondary.main} />*/}
            <Typography color="black" variant="h6">
              {name}
            </Typography>
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleLogoutClose}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  } else {
    return null;
  }
};

export default WelcomeName;
