import { ReactElement } from "react";
import { GridTableRow } from "../../../Common/UI/components/GridTableRow";
import { Grid, Typography } from "@mui/material";

export const GridRow = (props: {
  field: string;
  data: string | undefined | null;
}): ReactElement => {
  return (
    <GridTableRow>
      <Grid item xs={5}>
        <Typography>{props.field}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography>{props.data ?? ""}</Typography>
      </Grid>
    </GridTableRow>
  );
};
