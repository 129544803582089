import React, { useState } from "react";

export const useFormError = <T extends object>(initialFormError: T) => {
  const [formErrors, setFormErrors] = useState<T>(initialFormError);

  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validate: (value: string, ...validateFuncArgs: any) => string,
    ...validateFuncArgs: any
  ) => {
    const { name, value } = e.target;
    const validationResult = validate(value, validateFuncArgs);

    setFormErrors((prev) => ({ ...prev, [name]: validationResult }));
  };

  const isSubmittable = Object.values(formErrors).every((x) => x === "");

  return { formErrors, handleInputBlur, isSubmittable, setFormErrors };
};
