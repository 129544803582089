import "./NavBar.css";
import Toolbar from "@mui/material/Toolbar";
import { SetStateAction, useContext, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { ReactElement } from "react";
import { sizing } from "../../Common/UI/styles/sizing";
import logo from "../../img/DDashLogo.jpg";
import SpeedIcon from "@mui/icons-material/Speed";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { NavbarRoutes } from "../../MetaNavbarRoutes";

interface MenuItemCustom {
  name: string | undefined;
  routerPath: string | undefined;
  icon: ReactElement | undefined;
  children: MenuItemCustom[];
}

const NavBar = ({
  authorizedMenuItems,
}: {
  authorizedMenuItems: NavbarRoutes[];
}): ReactElement => {
  const [open, setOpen] = useState<{ [name: string]: boolean }>({});
  const [menuItems, setMenuItems] = useState<MenuItemCustom[]>([]);
  const [currentView, setCurrentView] = useState<string>("");
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    //menu open/close state
    const initialState: SetStateAction<{ [name: string]: boolean }> = {};
    const setInitialState = function (navItem: NavbarRoutes) {
      initialState[navItem.name] = false;
      navItem.children?.map((x) => {
        setInitialState(x);
      });
    };
    setOpen(initialState);
    authorizedMenuItems.sort((a, b) => a.order - b.order);
  }, [authorizedMenuItems]);

  useEffect(() => {
    if (state.path.length > 0) {
      setOpen((o) => ({ ...o, [state.path[0]]: true }));
      setCurrentView(state.path[state.path.length - 1]);
    } else {
      const initialState: SetStateAction<{ [name: string]: boolean }> = {};
      const setInitialState = function (navItem: NavbarRoutes) {
        initialState[navItem.name] = false;
        navItem.children?.map((x) => {
          setInitialState(x);
        });
      };
      setOpen(initialState);
      setCurrentView("");
    }
  }, [state.path, authorizedMenuItems]);

  const handleClick = (itemName: string | undefined) => {
    if (itemName !== undefined)
      setOpen((o) => ({ ...open, [itemName]: !o[itemName] }));
  };

  return (
    <Drawer
      sx={{
        width: sizing.drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizing.drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "black",
          color: "white",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <a href="/">
          <img className="logo" src={logo} alt="Torchy's Logo" />
        </a>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton component="a" href="/" selected={currentView === ""}>
          <ListItemIcon>
            <SpeedIcon htmlColor={currentView === "" ? "#000000" : "#FFFFFF"} />
          </ListItemIcon>
          <ListItemText disableTypography primary="Dashboard Home" />
        </ListItemButton>
        {authorizedMenuItems.map((menuItem, i) => {
          return (
            <Box key={i}>
              {menuItem.isActivePage === true ? (
                <ListItemButton
                  className="top-lvl-item"
                  key={menuItem.name}
                  component="a"
                  href={"/" + menuItem.routerPath}
                  selected={menuItem.name === currentView}
                >
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText disableTypography primary={menuItem.name} />
                </ListItemButton>
              ) : (
                <>
                  <ListItemButton
                    key={menuItem.name}
                    onClick={() => handleClick(menuItem.name)}
                  >
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText disableTypography primary={menuItem.name} />
                  </ListItemButton>
                  <Collapse
                    in={open[menuItem.name!]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding className="nav-children">
                      {menuItem.children?.map((child) => {
                        return (
                          <ListItemButton
                            key={menuItem.name + child.name!}
                            component="a"
                            href={"/" + child.routerPath}
                            sx={{ pl: 4 }}
                            selected={child.name === currentView}
                          >
                            <ListItemText
                              disableTypography
                              primary={child.name}
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              )}
            </Box>
          );
        })}
      </List>
    </Drawer>
  );
};

export default NavBar;
