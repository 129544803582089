import { ReactElement } from "react";
import "./GridTableRow.css";
import { Grid } from "@mui/material";

export const GridTableRow = function ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): ReactElement {
  return (
    <Grid container className={`grid-table-row ${className}`}>
      {children}
    </Grid>
  );
};
