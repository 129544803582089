import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { appRoles, config, pageNames } from "../../authConfig";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import Store from "./Store";
import { PageContainer } from "../../Common/UI/components/PageContainer";

function StoreInfoView(): ReactElement {
  const show = useIsAuthorized([appRoles.StoreInfo.ViewStores]);
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.store411],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>{show ? <Store></Store> : <></>}</PageContainer>
    </AuthenticatedTemplate>
  );
}

export default StoreInfoView;
