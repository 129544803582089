import { ReactElement } from "react";
import "./GridTableHeader.css";
import { Grid } from "@mui/material";

export const GridTableHeader = function ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): ReactElement {
  return (
    <Grid
      container
      className={
        className !== undefined && className.length > 0
          ? "grid-table-row " + className
          : "grid-table-row"
      }
    >
      {children}
    </Grid>
  );
};
