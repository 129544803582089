import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { appRoles, config, pageNames } from "../../authConfig";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import IntegrationStatuses from "../../Common/UI/components/IntegrationStatuses";

function IntegrationsView(): ReactElement {
  const showIntegrations = useIsAuthorized([appRoles.Integration.ViewStatus]);
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.integrations, pageNames.intStatus],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        {showIntegrations ? (
          <IntegrationStatuses showDateFilter={true}></IntegrationStatuses>
        ) : (
          <></>
        )}
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

export default IntegrationsView;
