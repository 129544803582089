import { Box } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import "./FlexRow.css";

export const FlexRow = function ({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <Box pb={"30px"} className="flex-row">
      {children}
    </Box>
  );
};
