import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { error } from "console";

const useFetchMsal = (): ((
  method: string,
  url: string,
  data?: any
) => Promise<any>) => {
  const { instance, inProgress } = useMsal();
  return (method, url, data) => {
    const promise = new Promise(function (myResolve, myReject) {
      if (inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: instance.getActiveAccount() ?? undefined,
          })
          .then((accessTokenResponse) => {
            let accessToken = accessTokenResponse.accessToken;

            // Call your API with token
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);

            let options = {
              method: method,
              headers: headers,
              body: data ? JSON.stringify(data) : null,
            };

            fetch(url, options)
              .then(async (value) => {
                if (value.ok === false) {
                  var res = await value.text();
                  myReject(res);
                } else {
                  var resp = await value.json();
                  myResolve(resp);
                }
              })
              .catch((error) => {
                myReject(error);
              });
          })
          .catch((error) => {
            myReject(error);
          });
      } else {
        myReject("Previous interaction in progress.");
      }
    });

    return promise;
  };
};

export default useFetchMsal;
