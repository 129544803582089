import { Typography } from "@mui/material";
import { theme } from '../../../Common/UI/styles/theme';

export default function ReleaseOnePointEight() {
  return (
    <>
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        RELEASE 1.8 - December 3rd, 2024
      </Typography>
      <ul>
        <li>
        Dashboard Home: <strong>NEW</strong> Uber Eats Store Status -
          <ul>
            <li style={{ listStyleType: "circle" }}>
            Use the two widgets on the Dashboard to quickly find which stores are Offline on Uber Eats
            </li>
          </ul>
        </li>
      </ul>
      <p>Note: POS Terminal Widget will be hidden until we are able to verify the correct logic for 		terminal statues from Bomgar </p>
      <ul>
        <li>
        Store 411 -
          <ul>
            <li style={{ listStyleType: "circle" }}>
            People Tab - <strong>NEW</strong> Market Partner name is now available under People tab in the Store 411 
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}
