import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Box,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import useFetchMsal from "../hook/useFetchMsal";
import { theme } from "../styles/theme";
import "./CreateTicket.css";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import { config } from "../../../authConfig";
import { CardContainer } from "./CardContainer";
import { LoadingIndicator } from "./Loading";
import CloseIcon from "@mui/icons-material/Close";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>{title}</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

function CreateTicket({
  ticketDescription = "",
  ticketSubject = "",
  buttonTitle,
  className = "",
}: {
  ticketDescription?: string;
  ticketSubject?: string;
  buttonTitle: ReactElement;
  className?: string;
}): ReactElement {
  const execute = useFetchMsal();
  const { accounts } = useMsal();
  //modal
  const [open, setOpen] = useState<boolean>(false);

  const [description, setDescription] = useState<string>(ticketDescription);
  const [subject, setSubject] = useState<string>(ticketSubject);
  const [email, setEmail] = useState<string>("");
  const [priority, setPriority] = useState<number>(3);
  const [status, setStatus] = useState<number>(2);
  const [groupId, setGroupId] = useState<number>(4000274586);

  const [createTicketLoading, setCreateTicketLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setEmail(accounts[0]?.username);
  }, []);

  function handleCreateTicket() {
    const payload = {
      subject: subject,
      description: description,
      email: email,
      priority: priority,
      status: status,
      group_id: groupId,
    };
    console.log(payload);

    setCreateTicketLoading(true);

    const url = config.rootAPIList.createTicket; // TODO add published code
    execute("POST", `${config.rootAPIUrl}${url}`, payload)
      .then((result) => {
        setCreateTicketLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubject(ticketSubject);
    setDescription(ticketDescription);
    setEmail(accounts[0]?.username);
    setCreateTicketLoading(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={className + " create-ticket-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            {buttonTitle}
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title="Create Ticket"
        key="create-ticket-key"
        open={open}
        onClose={handleClose}
      >
        <LoadingIndicator show={createTicketLoading}>
          <CardContainer className="create-ticket">
            <Grid container>
              <Grid item xs={12} className={"ticket-item"}>
                <TextField
                  fullWidth
                  required
                  label="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={"ticket-item"}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  value={email}
                  disabled={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={"ticket-item"}>
                <Typography>
                  Assigned to Group: Application Development
                </Typography>
              </Grid>
              <Grid item xs={6} className={"ticket-item"}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Priority
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority + ""}
                    label="Priority"
                    onChange={(event: SelectChangeEvent) => {
                      setPriority(event.target.value as unknown as number);
                    }}
                  >
                    <MenuItem value={1}>Low</MenuItem>
                    <MenuItem value={2}>Medium</MenuItem>
                    <MenuItem value={3}>High</MenuItem>
                    <MenuItem value={4}>Urgent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={"ticket-item"}></Grid>

              <Grid item xs={12}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "100%" },
                    height: "120px",
                  }}
                  noValidate
                  autoComplete="off"
                  className="ticket-description"
                >
                  <div>
                    <TextField
                      id="standard-multiline-static"
                      label="Description"
                      multiline
                      rows={4}
                      defaultValue={description}
                      variant="standard"
                      className="txt-area"
                      onChange={(ev) => {
                        setDescription(ev.target.value);
                      }}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <div className="spacer-small"></div>
            <Grid container className={"ticket-item"}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button
                  disabled={createTicketLoading}
                  onClick={handleCreateTicket}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardContainer>
        </LoadingIndicator>
      </SimpleDialog>
    </Box>
  );
}
export default CreateTicket;
