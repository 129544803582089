import { ReactElement } from "react";
import "./Loading.css";

export const LoadingIndicator = function ({
  children,
  show,
}: {
  show: boolean;
  children: React.ReactNode;
}): ReactElement {
  return show === true ? (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  ) : (
    <>{children}</>
  );
};
