import { ReactElement, useContext, useEffect, useState } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { config, pageNames } from "../../authConfig";
import { Box, Typography } from "@mui/material";
import { PipelinesGrid } from "./Components/PipelinesGrid";
import { DateRangePicker } from "./Components/DateRangePicker";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import PipelineRun from "./Interfaces/PipelineRun";

export const DevDashboard = (): ReactElement => {
  const { setState } = useContext(ApplicationLocationContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pipelineRuns, setPipelineRuns] = useState<PipelineRun[] | null>(null);

  const execute = useFetchMsal();

  useEffect(() => {
    setState(() => ({ path: [pageNames.devDashboard] }));
  }, [setState]);

  const handleLoadClick = async (
    startDate: string,
    endDate: string
  ): Promise<void> => {
    try {
      setIsLoading(() => true);

      const url = config.rootAPIList.devDashboard
        .replace("{startDate}", startDate)
        .replace("{endDate}", endDate);

      const response: PipelineRun[] = await execute(
        "GET",
        `${config.rootAPIUrl}${url}`
      );

      setPipelineRuns(() => response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(() => false);
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        p: 2,
        mx: 4,
        // minHeight: "80svh",
      }}
    >
      <Typography variant="h6" component={"h6"}>
        Dev Dashboard
      </Typography>
      <Box sx={{ p: 1, display: "flex", columnGap: 3 }}>
        <DateRangePicker {...{ isLoading, handleLoadClick }} />
      </Box>
      <PipelinesGrid {...{ isLoading, pipelineRuns }}></PipelinesGrid>
    </Box>
  );
};
