import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { theme } from "../../Common/UI/styles/theme";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import "./HelpContainer.css";
import { appRoles, pageNames } from "../../authConfig";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import HelpContainer from "./HelpContainer";

function HelpView(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.helpPage],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>{<HelpContainer></HelpContainer>}</PageContainer>
    </AuthenticatedTemplate>
  );
}

export default HelpView;
