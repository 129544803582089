import { AppBar, Breadcrumbs, Link, Toolbar } from "@mui/material";
import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { sizing } from "../../Common/UI/styles/sizing";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import WelcomeName from "./WelcomeName";
import { SignInButton } from "./SignInButton";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";

export const BreadcrumbsBar = (): ReactElement => {
  const { state, setState } = useContext(ApplicationLocationContext);
  const [steps, setSteps] = useState<ReactNode[]>([]);

  useEffect(() => {
    let newSteps: ReactNode[] = [];
    let homeColor = state.path.length > 0 ? "#000000" : "primary";
    newSteps.push(
      <Link key={"home"} href="/" color={homeColor} underline="none">
        Dashboard Home
      </Link>
    );
    for (let i = 0; i < state.path.length; i++) {
      let currentColor = i !== state.path.length - 1 ? "#000000" : "primary";
      const currentStep = state.path[i];
      newSteps.push(
        <Link key={currentStep} href="#" color={currentColor} underline="none">
          {currentStep}
        </Link>
      );
    }
    setSteps(newSteps);
  }, [state.path]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizing.drawerWidth}px)`,
        ml: `${sizing.drawerWidth}px`,
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "28px",
        paddingRight: "28px",
        boxShadow: "0px 10px 20px 0px #0000000A",
      }}
    >
      <Toolbar variant="dense">
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          sx={{
            fontSize: "18px",
            flex: 1,
          }}
        >
          {steps}
        </Breadcrumbs>
        <AuthenticatedTemplate>
          <WelcomeName />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignInButton />
        </UnauthenticatedTemplate>
      </Toolbar>
    </AppBar>
  );
};
