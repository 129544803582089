import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../../authConfig";
import { ReactElement } from "react";

export const SignInButton = (): ReactElement => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <div>
      <Button onClick={() => handleLogin()} color="inherit">
        Sign in
      </Button>
    </div>
  );
};
