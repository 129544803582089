import { ReactElement, useEffect } from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { CardContainer } from "./CardContainer";
import { GridTableHeader } from "./GridTableHeader";
import { GridTableRow } from "./GridTableRow";
import "./StoreWANWidget.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../styles/theme";
import { LoadingIndicator } from "./Loading";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export interface TableInfo {
  location: string;
  col1?: boolean;
  col1Name?: string;
  col2?: boolean;
  col2Name?: string;
  col3?: boolean;
  col3Name?: string;
  status: boolean; // all failed = true,
  key: string;
  updateDate: string | undefined;
}

export const StoreWANWidget = function ({
  items,
  isLoading,
  title,
  header,
  colWidth = "60px",
}: {
  items: TableInfo[];
  isLoading: boolean;
  title: string;
  header: string;
  colWidth?: string;
}): ReactElement {
  dayjs.extend(utc);
  return (
    <CardContainer className={"wan-status-list"} title={title}>
      <Box sx={{ fontSize: "12px", color: "var(--bs-gray-500)" }}>
        Last updated:{" "}
        {dayjs.utc(items[0]?.updateDate).local().format("MM/DD/YYYY hh:mm A")}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0",
          fontSize: "14px",
        }}
      >
        <Box sx={{ width: "200px" }}>{header}</Box>

        {!items.find((x) => !!x.col3Name)?.col3Name ? (
          <></>
        ) : (
          <Box sx={{ width: colWidth, textAlign: "center" }}>
            {items.find((x) => !!x.col3Name)?.col3Name}
          </Box>
        )}
        {!items.find((x) => !!x.col2Name)?.col2Name ? (
          <></>
        ) : (
          <Box sx={{ width: colWidth, textAlign: "center" }}>
            {items.find((x) => !!x.col2Name)?.col2Name}
          </Box>
        )}
        {!items.find((x) => !!x.col1Name)?.col1Name ? (
          <></>
        ) : (
          <Box
            sx={{ width: colWidth, textAlign: "center", marginRight: "10px" }}
          >
            {items.find((x) => !!x.col1Name)?.col1Name}
          </Box>
        )}
      </Box>

      <LoadingIndicator show={isLoading}>
        <Box className="table-wan">
          {items
            .sort((a, b) => {
              if (a.status == b.status) {
                return 0;
              } else if (a.status == false && b.status == true) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((x) => {
              return (
                <Box
                  key={x.location + x.key}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0",
                    fontSize: "14px",
                  }}
                >
                  <Box sx={{ width: "200px" }}>
                    {/* <Tooltip title={x.location}> */}
                      <Typography
                        sx={{ overflow: "hidden", textWrap: "nowrap" }}
                      >
                        {x.location}
                      </Typography>
                    {/* </Tooltip> */}
                  </Box>

                  {x.col3 === undefined ? (
                    <></>
                  ) : (
                    <Box sx={{ width: colWidth, textAlign: "center" }}>
                      {x.col3 === true ? (
                        <CheckCircleIcon
                          htmlColor={theme.palette.success.main}
                        />
                      ) : (
                        <HighlightOffIcon
                          htmlColor={theme.palette.error.main}
                        />
                      )}
                    </Box>
                  )}
                  {x.col2 === undefined ? (
                    <></>
                  ) : (
                    <Box sx={{ width: colWidth, textAlign: "center" }}>
                      {x.col2 === true ? (
                        <CheckCircleIcon
                          htmlColor={theme.palette.success.main}
                        />
                      ) : (
                        <HighlightOffIcon
                          htmlColor={theme.palette.error.main}
                        />
                      )}
                    </Box>
                  )}
                  {x.col1 === undefined ? (
                    <></>
                  ) : (
                    <Box sx={{ width: colWidth, textAlign: "center" }}>
                      {x.col1 === true ? (
                        <CheckCircleIcon
                          htmlColor={theme.palette.success.main}
                        />
                      ) : (
                        <HighlightOffIcon
                          htmlColor={theme.palette.error.main}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
        </Box>
      </LoadingIndicator>
    </CardContainer>
  );
};
