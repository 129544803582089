import { Typography } from "@mui/material";

export default function IntegrationsHelp() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        <a href="/integration-statuses">Integration Status</a>
      </Typography>
      <Typography variant="body1" paragraph>
        List Key Integration status as of day before. To query Integration
        Status for previous date, enter date and hit SEARCH.
      </Typography>
      <Typography variant="h6" gutterBottom>
        <a href="/employee-sync-errors">Emp Sync To POS</a>
      </Typography>
      <Typography variant="body1" paragraph>
        Employee Sync Process runs daily every 2-4 hours and updates POS with
        delta changes. Proactively, any processing errors should be available
        here.
      </Typography>
    </>
  );
}
