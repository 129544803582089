import { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import { CardContainer } from "./CardContainer";
import "./NumberWidget.css";
import { LoadingIndicator } from "./Loading";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const NumberWidget = function ({
  number,
  text,
  icon,
  className,
  isLoading,
  lastUpdatedDate,
}: {
  text?: string;
  number?: number;
  className?: string;
  icon?: ReactElement;
  isLoading: boolean;
  lastUpdatedDate: string | undefined;
}): ReactElement {
  dayjs.extend(utc);
  return (
    <CardContainer className={className}>
      <Grid container className="number-wan">
        <Grid item xs={8}>
          <LoadingIndicator show={isLoading}>
            <Typography className="number">{number}</Typography>
          </LoadingIndicator>
          <Typography className="text">{text}</Typography>
          <Typography className="update-date">{`Last updated: ${dayjs
            .utc(lastUpdatedDate)
            .local()
            .format("MM/DD/YYYY hh:mm A")}`}</Typography>
        </Grid>
        <Grid item xs={4} className="icon-number-wan">
          {icon}
        </Grid>
      </Grid>
    </CardContainer>
  );
};
