import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridSortItem } from "@mui/x-data-grid";
import "./LRSGrid.css";

export const columns: GridColDef<LRSData>[] = [
  { field: "LocationNumber", headerName: "Number", flex: 1, minWidth: 90 },
  {
    field: "Name",
    headerName: "Name",
    flex: 3,
    minWidth: 200,
    editable: false,
  },
  {
    field: "LocationGuid",
    headerName: "Guid",
    flex: 3,
    minWidth: 250,
    editable: false,
  },
  {
    field: "OrderCount",
    headerName: "Order Count",
    type: "number",
    flex: 1,
    minWidth: 90,
    editable: false,
  },
];

export interface LRSData {
  LocationNumber: number;
  Name: string;
  LocationGuid: string;
  OrderCount: number;
}

function LRSGrid({
  lrsData,
}: {
  lrsData: LRSData[] | undefined;
}): ReactElement {
  const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
    {
      field: "OrderCount",
      sort: "asc",
    },
  ]);

  return lrsData === undefined || lrsData?.length === 0 ? (
    <></>
  ) : (
    <Box sx={{ width: "100%", height: "calc(100vh - 300px)" }}>
      <DataGrid
        className="lrsCalendar"
        rows={lrsData}
        columns={columns}
        getRowId={(row: any) => row.LocationNumber}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  );
}
export default LRSGrid;
