import { ReactElement } from "react";
import "./PageContainer.css";

export const PageContainer = function ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement {
  return <div className="page-container">{children}</div>;
};
