import { SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";

export const useForm = <T,>(initialFormData: T) => {
  const [formData, setFormData] = useState<T>(initialFormData);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMuiSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNativeSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return {
    formData,
    handleInputChange,
    setFormData,
    handleMuiSelectChange,
    handleNativeSelectChange,
  };
};
